import classNames from 'classnames';
import { useState } from 'react';

import CardLink from '../Buttons/CardLink';
import { handleError } from '~/helper/imgOnError';
import tileStyles from '~/styles/components/Utility/Layout/Tile.css';

import type { ReactNode, SyntheticEvent } from 'react';

export const links = () => [{ rel: 'stylesheet', href: tileStyles }];

type Props = {
  children?: ReactNode;
  className?: string;
  overlay?: boolean;
  src?: string; // Optional for card shapes with solid background
  [key: string]: unknown;
  style?: React.CSSProperties;
} & (
{
  to?: never;
  transition?: never;
} |
{
  to: string;
  transition?: boolean;
});


const Tile = ({ children, className, overlay, src, to, transition, style, ...rest }: Props) => {
  const [isLoaded, setIsLoaded] = useState(true);

  const handleImageError = (e: SyntheticEvent<HTMLImageElement>) => {
    setIsLoaded(true);
    handleError(e);
  };

  return to ?
  <CardLink
    to={to}
    data-testid="tile__link__navigate"
    role="link"
    className={classNames(
      'tile__card',
      { '-transition': transition },
      { '-overlay': overlay },
      { 'is-loaded': isLoaded },
      className
    )}
    style={style}
    {...rest}>

      {src &&
    <img
      src={src.replace(/size=small/i, 'size=xsmall')}
      className="tile__image"
      alt=""
      loading="lazy"
      onError={handleImageError}
      onLoad={() => setIsLoaded(true)} />}


      {children}
    </CardLink> :

  <div
    className={classNames('tile__card', { '-overlay': overlay }, { 'is-loaded': isLoaded }, className)}
    style={style}>

      {src &&
    <img
      src={src.replace(/size=small/i, 'size=xsmall')}
      className="tile__image"
      alt=""
      loading="lazy"
      onError={handleImageError}
      onLoad={() => setIsLoaded(true)} />}


      {children}
    </div>;

};

type TileLayoutProps = {
  children: ReactNode;
};

const TileLayout = ({ children }: TileLayoutProps) => {
  return <div className="tile__layout">{children}</div>;
};

Tile.Layout = TileLayout;

export default Tile;